import {ErrorMessages} from "../extensions/ErrorMessages";
import {ErrorObject, ErrorStatus, SuccessObject} from "../types/AuthState";

export const BACKEND_BASE = process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:5001'

const standalone = process.env.REACT_APP_IS_STANDALONE ?? 'true';

export const IS_STANDALONE = standalone === 'true';

export const GetToken = () => {
    const auth = localStorage.getItem('auth');

    if (auth)
        return JSON.parse(auth).token;

    return null;
}

export const Logout = () => {
    localStorage.removeItem('auth');
    window.location.reload();
}

export const LoginService = {
    systemLogin: async (password: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/auth`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({password})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.text();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    caseLogin: async (id: string, password: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/auth/existing`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({id, password})
            });

            if (response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.text();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    verifyAuth: async () => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/auth`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                }
            });

            if (response.status > 400) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            return Promise.reject(new Error('unauthorized'));
        }
    },
    verifyExternalAuth: async (token: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/auth`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (response.status > 400) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return Promise.resolve();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    adminLogin: async (email: string, password: string) => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/admin/auth`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({email, password})
            });

            if (response.status > 400) {
                Logout();
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return response.text();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    updatePassword: async (password: string): Promise<SuccessObject | ErrorObject> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/User/password`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: JSON.stringify({password})
            });

            if (response.status === 401) {
                return Promise.reject(new Error('unauthorized'));
            }

            if (response.status === 422) {
                const reason: ErrorStatus = await response.json();

                return Promise.reject(new Error(reason.errors[0]));
            }

            if (response.status === 404)
                return {error: 'Nie znaleziono użytkownika!'}

            return {success: true};
        } catch (e) {
            Logout();
            throw new Error('unauthorized');
        }
    },
}
