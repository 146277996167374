import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../extensions/Locations";
import React, {useEffect, useState} from "react";
import ReportService from "../../services/ReportService";
import {
    AuthorType,
    Report,
    ReportingStatus,
    ReportMessage,
    ReportMessageRequest,
    Reports,
    ReportState
} from "../../types/Reports";
import {Form, Input, message, Pagination} from "antd";
import {useAuth} from "../../extensions/Auth";
import {ErrorMessages} from "../../extensions/ErrorMessages";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import {useForm} from "antd/es/form/Form";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {FormatDateSimpleWithTime} from "../../extensions/DateFormatter";
import './ReportStatus.css';
import Logo from "../../assets/images/logo.webp";
import AttachmentsDragger from "../../components/AttachmentsDragger/AttachmentsDragger";
import {Attachment} from "../../types/StaticAsset";
import {StaticService} from "../../services/StaticService";
import TextArea from "antd/es/input/TextArea";

export const ReportStateMapper = (state: ReportState) => {
    switch (state) {
        case ReportState.New:
            return <span className="text-orange-600">Oczekujące</span>;
        case ReportState.Read:
            return <span className="text-blue-600">Odczytano</span>;
        case ReportState.Answered:
            return <span className="text-blue-600">Nowa odpowiedź</span>;
        case ReportState.Investigation:
            return <span>W trakcie wyjaśniania</span>;
        case ReportState.Closed:
            return <span className="text-gray-600">Zamknięte</span>;
        case ReportState.Resolved:
            return <span className="text-green-600">Rozwiązane</span>;
    }
}

const PAGE_SIZE = 5;

export const AuthorMapper = (author: AuthorType) => {
    if (author === AuthorType.User) return 'użytkownika';

    return 'operatora';
}

export default function ReportStatus() {
    const auth = useAuth();
    const [activeReport, setActiveReport] = useState({} as Report);
    const [activePage, setActivePage] = useState(1);
    const [categories, setCategories] = useState([] as Reports[]);
    const [reportingStatuses, setReportingStatuses] = useState([] as ReportingStatus[]);
    const [messages, setMessages] = useState([] as ReportMessage[]);

    const [form] = useForm();

    const [msgForm] = Form.useForm();
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);

    const filter = (p = 1, input = activeReport.messages) => setMessages(input.filter((_, index) => index >= (p - 1) * PAGE_SIZE && index < p * PAGE_SIZE));

    const submitReport = () => {
        msgForm.validateFields().then((values) => {
            const request: ReportMessageRequest = {
                ...values,
                attachments: attachments.map(x => x.asset),
            }

            ReportService.addReportMessage(request)
                .then(x => {
                    message.success('Wiadomość została wysłana!');
                    msgForm.resetFields();

                    setAttachments([]);
                    setFileList([]);

                    const page = Math.ceil(x.messages.length / PAGE_SIZE);
                    filter(page, x.messages);
                    setActivePage(page);
                    setActiveReport(x);
                })
                .catch(x => {
                    message.error('Wystąpił błąd podczas wysyłania wiadomości!');
                });
        });
    }

    const authenticate = () => {
        form.validateFields().then((values) => {
            auth.handleLogin(values.id, values.password)
                .then((x: boolean) => {
                    if (x) {
                        message.success('Zalogowano pomyślnie!');
                        auth.navigate('/report/status');
                    } else {
                        message.error('Nieprawidłowe hasło!');
                    }
                });
        });
    }

    useEffect(() => {
        if (!auth.authCase?.id) return;

        ReportService.getCategories()
            .then((x) => setCategories(x))
            .catch(x => message.error('Wystąpił błąd podczas pobierania kategorii zgłoszeń.'));

        ReportService.getReportingStatuses()
            .then((x) => setReportingStatuses(x))
            .catch(x => message.error('Wystąpił błąd podczas pobierania statusów osoby zgłaszającej.'));

        ReportService.getReport()
            .then(x => {
                setActiveReport(x)

                filter(activePage, x.messages);
            })
            .catch(x => {
                if (x.message === ErrorMessages.forbidden) {
                    message.error("Sesja wygasła!")
                    auth.handleLogout();
                    return;
                }

                message.error("Nie udało się pobrać zgłoszenia")
            })
    }, [auth]);
    return (
        <div>
            <RetoricBreadcrumbs locations={Locations['report/status']}>Sprawdź status zgłoszenia</RetoricBreadcrumbs>
            {auth.authCase?.id && <div className="flex flex-col justify-center items-center">
                <RetoricCard className="self-stretch flex flex-col gap-4 bg-none report lg:p-4 p-0">
                    <div>
                        <h2 className="text-xl tracking-wider font-light text-center lg:text-left">{activeReport.title}</h2>
                        <h2 className="text-md tracking-wide italic font-light text-center lg:text-left mb-4">Identyfikator
                            zgłoszenia: {activeReport.id}</h2>
                        <div className="flex lg:flex-row flex-col lg:justify-between items-center">
                            <span
                                className="italic">Zgłoszenie utworzono: {FormatDateSimpleWithTime(activeReport.createdAt)}</span>
                            <span
                                className="italic">Zgłaszający: {activeReport.name ?? 'Anonimowy'}</span>
                            <span
                                className="italic">Kategoria: {categories.find(x => x.id === activeReport.categoryId)?.title}</span>
                            <span
                                className="italic">Status osoby zgłaszającej: {reportingStatuses.find(x => x.id === activeReport.reportingStatusId)?.title}</span>
                            <span className="italic">Status zgłoszenia: {ReportStateMapper(activeReport.status)}</span>
                        </div>
                    </div>
                    <RetoricCard className="bg-offwhite flex flex-col">
                        <h3 className="text-lg tracking-wider">Treść zgłoszenia</h3>
                        <div className={"flex flex-col"}>
                            {activeReport?.description && activeReport.description.split('\n').map((x, index) => <p
                                key={`message-${index}`}>{x}</p>)}
                        </div>
                        <div>
                            <h3 className="text-lg tracking-wider font-light">Załączniki</h3>
                            <ul>
                                {activeReport?.attachments && activeReport.attachments.map(x => <li
                                    key={`attachment-${x.id}`}><span className="cursor-pointer hover:text-red-600"
                                                                     onClick={() => StaticService.openAttachment(x.name)}>{x.name}</span>
                                </li>)}
                                {activeReport?.attachments && activeReport.attachments.length === 0 &&
                                    <span className="italic">Brak załączników</span>}
                            </ul>
                        </div>
                    </RetoricCard>
                    <RetoricCard className="bg-none flex flex-col self-stretch gap-4 p-0 lg:mt-4">
                        <h3 className="text-lg tracking-wider">Wiadomości</h3>
                        {activeReport?.messages && <div className="flex flex-col gap-4">
                            {messages.map(x =>
                                <RetoricCard key={`message-${x.createdAt}`}
                                             className={`flex flex-col gap-2 ${x.authorType === AuthorType.User ? 'lg:mr-16 mr-8 bg-offwhite' : 'lg:ml-16 ml-8 bg-gray-200/30 text-slate-950'}`}>
                                    <div>
                                        <span>Wiadomość wysłana {FormatDateSimpleWithTime(activeReport.createdAt)} przez {AuthorMapper(x.authorType)} {x.authorType === AuthorType.Operator && x.author && `(${x.author.name})`}</span>
                                        <h3 className="text-lg tracking-wider font-light mb-1">{x.title}</h3>
                                        <div className={"flex flex-col"}>
                                            {x?.description && x.description.split('\n').map((x, index) => <p
                                                key={`message-${index}`}>{x}</p>)}
                                        </div>
                                        {x?.attachments && x.attachments.length > 0 && <div>
                                            <h3 className="text-md tracking-wider font-light italic mt-2">Załączniki</h3>
                                            <ul>
                                                {x?.attachments && x.attachments.map(x => <li
                                                    key={`attachment-${x.id}`}><span
                                                    className="cursor-pointer hover:text-red-600"
                                                    onClick={() => StaticService.openAttachment(x.name)}>{x.name}</span>
                                                </li>)}
                                            </ul>
                                        </div>}
                                    </div>
                                </RetoricCard>)
                            }
                            {messages.length > 0 && <Pagination className="self-center" current={activePage}
                                                                locale={{
                                                                    next_page: "Następna strona",
                                                                    prev_page: "Poprzednia strona"
                                                                }}
                                                                pageSize={PAGE_SIZE}
                                                                total={activeReport?.messages.length}
                                                                onChange={(p) => {
                                                                    filter(p)
                                                                    setActivePage(p)
                                                                }}/>}
                            {messages.length === 0 && <span className="italic">Brak wiadomości</span>}
                        </div>}
                    </RetoricCard>
                    <RetoricCard className="bg-none flex flex-col self-stretch gap-4 p-0 lg:mt-4">
                        <h3 className="text-lg tracking-wider">Wyślij wiadomość</h3>
                        <Form
                            onFinish={submitReport}
                            form={msgForm}
                            layout="vertical">
                            <Form.Item label="Tytuł wiadomości" required name="title"
                                       rules={[{required: true, message: "Tytuł wiadomości jest wymagany!"}]}>
                                <Input className="rounded-none" placeholder="Tytuł wiadomości..." size="large"/>
                            </Form.Item>
                            <Form.Item label="Treść wiadomości" required name="description"
                                       rules={[{required: true, message: "Treść wiadomości jest wymagana!"}]}>

                                <TextArea rows={6} placeholder={"Treść wiadomości..."}></TextArea>
                            </Form.Item>
                            <Form.Item>
                                <AttachmentsDragger attachments={attachments} setAttachments={setAttachments}
                                                    fileList={fileList} setFileList={setFileList}/>
                            </Form.Item>
                            <Form.Item className="flex justify-center">
                                <RetoricButton htmlType="submit"
                                               className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Wyślij
                                    wiadomość</RetoricButton>
                            </Form.Item>
                        </Form>
                    </RetoricCard>
                </RetoricCard>
            </div>}
            {!auth.authCase?.id && <div className="flex flex-row justify-center items-center mt-4">
                <RetoricCard
                    className="bg-offwhite flex flex-col lg:px-16 lg:py-8 gap-4 justify-between items-center flex-1">
                    <div className="self-stretch flex flex-col gap-2 items-stretch">
                        <h1 className="text-2xl tracking-wider font-light">Sprawdź status zgłoszenia</h1>
                        <p className="text-justify mb-8">Podaj identyfikator zgłoszenia oraz hasło, aby sprawdzić jego
                            status.</p>
                        <Form layout="vertical" className="self-stretch" form={form}
                              onFinish={authenticate}>
                            <Form.Item label="Identyfikator zgłoszenia" required name="id"
                                       rules={[{required: true, message: "Identyfikator zgłoszenia jest wymagany!"}]}>
                                <Input className="rounded-none" placeholder="FFFFFFFF-FFFF-FFFF-FFFF-..." size="large"/>
                            </Form.Item>
                            <Form.Item label="Hasło" required name="password"
                                       rules={[{required: true, message: "Hasło jest wymagane!"}]}>
                                <Input.Password className="rounded-none" placeholder="Hasło..." size="large"
                                                type="password"/>
                            </Form.Item>
                            <Form.Item>
                                <RetoricButton htmlType="submit" size="lg"
                                               className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Sprawdź
                                    status</RetoricButton>
                            </Form.Item>
                        </Form>
                    </div>
                </RetoricCard>
                <div className="flex-1 flex-row justify-center hidden lg:flex">
                    <img src={Logo} className="w-48" alt="Logo Signalist"/>
                </div>
            </div>}
        </div>
    )
}
