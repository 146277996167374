import React, {useEffect, useState} from 'react';
import {message, Modal, Tooltip} from "antd";
import './GenerateNewReportIdModal.css';
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import SignalistAdminService from "../../../../services/SignalistAdminService";
import {CopyOutlined, RedoOutlined} from "@ant-design/icons";

export interface ReportDateRangeModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

function GenerateNewReportIdModal({open, setOpen}: ReportDateRangeModalProps) {
    const [reportId, setReportId] = useState('');

    const newId = () => {
        SignalistAdminService.getReportId()
            .then((x) => {
                setReportId(x.replaceAll('"', ''));
            })
            .catch(() => {
                message.error('Nie udało się wygenerować identyfikatora, spróbuj ponownie później.')
            })
    }

    const closeModal = () => {
        newId();
        setOpen(false);
    }

    const copy = () => {
        navigator.clipboard.writeText(reportId);
        message.success('Skopiowano identyfikator!')
    }

    useEffect(() => {
        newId();

        return () => {
            setOpen(false);
        }
    }, []);

    return (
        <Modal
            title={'Wygeneruj nowy identyfikator zgłoszenia'}
            centered
            footer={[
                <RetoricButton key="back" outline className="mr-4" onClick={closeModal}>
                    Zamknij
                </RetoricButton>,
            ]}
            onCancel={closeModal}
            open={open}
            width={1000}
        >
            <div className="flex flex-col justify-start">
                <Infobox title={'Generowanie identyfikatora zgłoszenia'} rows={[
                    'Ta funkcja pozwala na wygenerowanie nowego identyfikatora zgłoszenia.',
                    'Jeżeli przyjmujesz zgłoszenia telefonicznie, osobiście lub w inny sposób poza system, wygeneruj nowy identyfikator i podaj go osobie zgłaszającej. A następnie wprowadź zgłoszenie do systemu korzystając z tego identyfikatora.',
                ]}/>
                <p className="text-2xl mt-4 mx-auto">Nowy identyfikator zgłoszenia
                    <Tooltip title="Wygeneruj nowy identyfikator">
                        <RedoOutlined className="ml-2 hover:text-brandColor-500" onClick={newId}/>
                    </Tooltip></p>
                {reportId && <p className="text-xl mt-2 mx-auto">{reportId}
                    <Tooltip title="Skopiuj identyfikator">
                        <CopyOutlined className="ml-2 hover:text-brandColor-500" onClick={copy}/>
                    </Tooltip>
                </p>}
            </div>
        </Modal>
    );
}

export default GenerateNewReportIdModal;
