import React from 'react';
import {DatePicker, Form, Modal} from "antd";
import './ReportDateRangeModal.css';
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import Infobox from "../../../../designsystems/Infobox/Infobox";
import SignalistAdminService from "../../../../services/SignalistAdminService";
import pl from 'antd/es/date-picker/locale/pl_PL';
import dayjs from "dayjs";

export interface ReportDateRangeModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

function ReportDateRangeModal({open, setOpen}: ReportDateRangeModalProps) {
    const [fromDate, setFromDate] = React.useState<Date | undefined>(undefined);
    const [toDate, setToDate] = React.useState<Date | undefined>(undefined);

    const openReport = () => {
        let from = undefined;
        let to = undefined;

        if (fromDate) {
            from = fromDate;
            from.setHours(0, 0, 0, 0);
        }

        if (toDate) {
            to = toDate;
            to.setHours(23, 59, 59, 999);
        }

        SignalistAdminService.openFullReport(from, to)
        closeModal();
    }

    const closeModal = () => {
        setToDate(undefined);
        setFromDate(undefined);
        setOpen(false);
    }

    return (
        <Modal
            title={'Wybierz zakres dat'}
            centered
            footer={[
                <RetoricButton key="back" outline className="mr-4" onClick={closeModal}>
                    Anuluj
                </RetoricButton>,
                <RetoricButton key="submit" onClick={() => openReport()}>
                    Generuj raport
                </RetoricButton>,
            ]}
            onCancel={closeModal}
            open={open}
            width={1000}
        >
            <Form className="flex flex-col" layout={'vertical'}>
                <Infobox title={'Generowanie raportu'} rows={[
                    'Raporty generowane są w formacie PDF. Jeżeli wybierzesz zakres dat to wygenerowany raport będzie zawierał wszystkie zgłoszenia z tego okresu.',
                    'Jeżeli nie wybierzesz zakresu dat to wygenerowany raport będzie zawierał wszystkie zgłoszenia od początku.',
                    'Wygenerowany raport zostanie otwarty w nowym oknie.'
                ]}/>
                <Form.Item label={"Zakres daty od dnia"} className="mt-4">
                    <DatePicker locale={{...pl}} onChange={(d) => setFromDate(d?.toDate())}
                                value={fromDate ? dayjs(fromDate) : undefined}
                                className={"w-full bg-offwhite rounded-none"}/>
                </Form.Item>
                <Form.Item label={"Zakres daty do dnia"}>
                    <DatePicker locale={{...pl}} onChange={(d) => setToDate(d?.toDate())}
                                value={toDate ? dayjs(toDate) : undefined}
                                className={"w-full bg-offwhite rounded-none"}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ReportDateRangeModal;
