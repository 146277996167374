import {Location} from "../../types/AuthState";
import React, {ReactNode} from "react";
import {HomeOutlined} from "@ant-design/icons";
import {useAuth} from "../../extensions/Auth";

export interface RetoricBreadcrumbProps {
    children: ReactNode;
    locations?: Location[];
    isAdmin?: boolean;
}

export default function RetoricBreadcrumbs({children, locations, isAdmin}: RetoricBreadcrumbProps) {
    const auth = useAuth();
    const navigate = isAdmin ? auth.adminNavigate : auth.navigate;

    return (
        <div className="self-stretch flex flex-col">
            <h3 className="lg:text-3xl text-2xl text-slate-600 text-center lg:text-left tracking-wide self-stretch lg:mb-1 mt-2 lg:mt-0">{children}</h3>
            {locations && locations.length > 0 ? <div className="lg:block hidden">
                    <HomeOutlined className="text-slate-600 text-sm cursor-pointer hover:text-brandColor-800"
                                  onClick={() => navigate('/')}/>
                    <span className="text-slate-600 text-sm font-medium">{' / '}</span>
                    {locations.map((location, index) =>
                        (index !== locations.length - 1) ?
                            <span key={`location-${location.title}`}>
                                <span
                                    className="text-slate-600 text-sm cursor-pointer hover:text-brandColor-800"
                                    onClick={() => navigate(location.path)}>
                                    {location.title}
                                </span>
                                <span className="text-slate-600 text-sm font-medium">{' / '}</span>
                            </span>
                            :
                            <span key={`location-${location.title}`} className="text-slate-600 text-sm">
                                {location.title}
                            </span>
                    )}
                </div>
                :
                ''
            }
        </div>
    )
}
