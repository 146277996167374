import RetoricBreadcrumbs from "../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import React from "react";
import {FolderAddOutlined, FolderOpenOutlined} from "@ant-design/icons";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import {useAuth} from "../../extensions/Auth";

export default function Home() {
    const auth = useAuth();

    return (
        <div>
            <RetoricBreadcrumbs>Panel zgłoszeń</RetoricBreadcrumbs>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-4">
                <RetoricCard className="bg-offwhite flex flex-col justify-center items-center gap-4 lg:p-8">
                    <FolderAddOutlined className="text-4xl"/>
                    <h2 className="text-xl tracking-wider font-light">Utwórz nowe zgłoszenie</h2>
                    <p>Utwórz anonimowe zgłoszenie, po utworzeniu dostaniesz unikalny identyfikator i hasło,
                        które pozwoli Ci na sprawdzenie jego statusu w przyszłości.</p>
                    <RetoricButton size='md' onClick={() => auth.navigate('/report/new')}>Nowe
                        zgłoszenie</RetoricButton>
                </RetoricCard>
                <RetoricCard className="bg-offwhite flex flex-col justify-center items-center gap-4 lg:p-8">
                    <FolderOpenOutlined className="text-4xl"/>
                    <h2 className="text-xl tracking-wider font-light">Sprawdź status zgłoszenia</h2>
                    <p>Jeżeli utworzyłeś już zgłoszenie możesz sprawdzić jego postęp. Będziesz musiał podać unikalny
                        identyfikator i hasło, które zostało wyświetlone podczas jego tworzenia.</p>
                    <RetoricButton size='md' onClick={() => auth.navigate('/report/status')}>Sprawdź
                        status</RetoricButton>
                </RetoricCard>
            </div>
        </div>
    )
}
