import React, {useEffect} from 'react';
import './App.css';
import {message, theme} from "antd";
import {setTwoToneColor} from '@ant-design/icons';
import Landing from "./screens/Landing/Landing";
import {useAuth} from "./extensions/Auth";
import Main from "./screens/Main/Main";
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {LoginService} from "./services/LoginService";
import Admin from "./screens/Admin/Admin";

function App() {
    const auth = useAuth();

    const {
        token: {colorBgContainer},
    } = theme.useToken();
    setTwoToneColor('#D1522E');

    useEffect(() => {
        if (window.location.search.startsWith('?auth=')) {
            const token = window.location.search.replace('?auth=', '');

            auth.handleExternalLogin(token.trim())
                .then(() => {
                    auth.navigate('/');
                })
                .catch(() => {
                    message.error("Nie udało się zalogować, spróbuj ponownie lub zaloguj się za pomocą hasła.");
                })
        } else if (auth.authCase?.isAuthenticated) {
            LoginService.verifyAuth().catch(x => {
                message.error("Twoja sesja wygasła")
                auth.handleLogout();
            })
        }

        return () => {
        };
    }, [auth]);

    return (
        <div className="flex flex-col justify-center items-center w-full min-h-[100vh]">
            {auth.authCase?.isAuthenticated && !auth.authCase?.isAdmin && <Main/>}
            {auth.authCase?.isAuthenticated && auth.authCase?.isAdmin && <Admin/>}
            {!auth.authCase?.isAuthenticated && <Landing/>}
        </div>
    );
}

export default App;
