import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../extensions/Auth";
import './Users.css';
import ConfirmationModal, {ConfirmationModalProps} from "../../../designsystems/ConfirmationModal/ConfirmationModal";
import {Empty, notification, Space, Table, Tooltip} from "antd";
import {User} from "../../../types/User";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {UserService} from "../../../services/UserService";

export default function Users() {
    const auth = useAuth();
    const [userList, setUserList] = useState([] as User[]);
    const [userDeleteModal, setUserDeleteModal] = useState(false);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [api, contextHolder] = notification.useNotification();

    const deleteUser = (email: string) => {
        UserService.deleteUser(email).then((x) => {
            if ("error" in x) {
                api.error({
                    message: `Błąd`,
                    description: x.error,
                    placement: 'top'
                });
                return;
            }

            setUserList(userList.filter(x => x.email !== email));

            api.info({
                message: `Użytkownik usunięty`,
                description: `Użytkownik ${email} został usunięty z systemu`,
                placement: 'top'
            });
        });
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Imię i nazwisko',
            dataIndex: 'name',
            key: 'name',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (value, record) => <span className="block">{value}</span>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Edytuj użytkownika">
                        <EditOutlined onClick={() => auth.adminNavigate(`/users/edit/${record.email}`)}/>
                    </Tooltip>
                    <Tooltip title="Usuń użytkownika">
                        <DeleteOutlined onClick={() => {
                            const payload = {
                                title: 'Usuń użytkownika',
                                open: true,
                                setOpen: setUserDeleteModal,
                                yesAction: () => {
                                    deleteUser(record.email)
                                    setUserDeleteModal(false);
                                },
                                noAction: () => setUserDeleteModal(false),
                            } as ConfirmationModalProps;

                            payload.infoboxTitle = "Wybrany użytkownik zostanie usunięty"
                            payload.children = <>
                                <p className="text-slate-600">Usunięty użytkownik nie może zostać przywrócony, czy
                                    jesteś pewien, że chcesz usunąć {record.name} ({record.email})?</p>
                            </>

                            setModelPayload(payload);
                            setUserDeleteModal(true);
                        }}/>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (!auth.authCase?.isAdmin) {
            auth.handleLogout();
            return;
        }

        UserService.getUsers().then(x => {
            setUserList(x);
        });

    }, [auth]);
    return (
        <div>
            <RetoricBreadcrumbs locations={Locations['admin/users']} isAdmin>Operatorzy</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite mt-4'}>
                {contextHolder}
                <div className="flex lg:flex-row flex-col justify-between">
                    <Space className="flex-1 mb-4 lg:justify-start justify-center">
                        <RetoricButton outline onClick={() => auth.adminNavigate('/users/new')}
                                       className="min-w-[150px]">Dodaj</RetoricButton>
                    </Space>
                    <div className={'users__new'}></div>
                </div>
                <Table columns={columns} dataSource={userList} rowKey={'id'} scroll={{x: 600}}
                       pagination={{position: ['bottomCenter']}}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
                <ConfirmationModal {...modelPayload} open={userDeleteModal} setOpen={setUserDeleteModal}/>
            </RetoricCard>
        </div>
    )
}
