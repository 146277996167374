import React, {ReactNode} from "react";

export interface RetoricCardProps {
    className?: string;
    children?: ReactNode;
    bordered?: boolean;
    hoverable?: boolean;
    onClick?: () => void;
}

export default function RetoricCard({className, children, bordered, hoverable, onClick}: RetoricCardProps) {
    const enrichClassName = (classes: string | undefined) => {
        if (classes === undefined) return 'bg-offwhite';

        const classesArray = classes.split(' ');
        const bg = classesArray.find(x => x.startsWith('bg-'));
        const rounding = classesArray.find(x => x.startsWith('rounded-'));
        const hoverShadow = classesArray.find(x => x.startsWith('hover:shadow-'));
        const cursorPointer = classesArray.find(x => x.startsWith('hover:cursor-pointer'));

        if (!bg) classes += ' bg-offwhite';
        if (!rounding) classes += ' rounded-xl';
        if (hoverable && !hoverShadow) classes += ' hover:shadow-lg';
        if (onClick && !cursorPointer) classes += ' hover:cursor-pointer';

        return classes;
    }

    return (
        <div className={`p-4 ${enrichClassName(className)}`} onClick={onClick ? onClick : () => {
        }}>
            {children}
        </div>
    )
}
