import React, {createContext, useContext, useEffect, useState} from "react";
import AuthState, {AuthCase} from "../types/AuthState";
import Router from "./Routing";
import {LoginService} from "../services/LoginService";
import AdminRouter from "./AdminRouting";

export function ProvideAuth({children}: any) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

const authContext = createContext({} as AuthState);

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const authValue = localStorage.getItem('auth');
    const [authCase, setAuthCase] = useState<AuthCase>(authValue ? JSON.parse(authValue) : {} as AuthCase);
    const [route, setRoute] = useState(window.location.pathname);

    useEffect(() => {
        function popStateHandler() {
            setRoute(window.location.pathname);
        }

        window.addEventListener('popstate', popStateHandler);

        return () => {
            window.removeEventListener('popstate', popStateHandler)
        }
    }, []);

    const handleLogin = async (id: string, password: string): Promise<boolean> => {
        try {
            var token = await LoginService.caseLogin(id, password);

            const auth: AuthCase = {
                id: id,
                isAuthenticated: true,
                token: token
            };

            localStorage.setItem('auth', JSON.stringify(auth));
            setAuthCase(auth);

            return true
        } catch (e) {
            return false;
        }
    };

    const handleAdminLogin = async (email: string, password: string): Promise<boolean> => {
        try {
            const token = await LoginService.adminLogin(email, password);

            const auth: AuthCase = {
                isAuthenticated: true,
                isAdmin: true,
                token: token
            };

            localStorage.setItem('auth', JSON.stringify(auth));
            setAuthCase(auth);

            return true
        } catch (e) {
            return false;
        }
    };

    const handleSystemLogin = async (password: string): Promise<boolean> => {
        try {
            var token = await LoginService.systemLogin(password);

            const auth: AuthCase = {
                isAuthenticated: true,
                token: token
            };

            localStorage.setItem('auth', JSON.stringify(auth));
            setAuthCase(auth);

            return true
        } catch (e) {
            return false;
        }
    }

    const handleExternalLogin = async (token: string) => {
        LoginService.verifyExternalAuth(token)
            .then(x => {
                const auth: AuthCase = {
                    isAuthenticated: true,
                    token: token
                };

                localStorage.setItem('auth', JSON.stringify(auth));
                setAuthCase(auth);

                return Promise.resolve();
            })
            .catch(x => {
                return Promise.reject(new Error('Invalid token'))
            });
    }

    const navigate = (path: string, external = false) => {
        if (external) {
            window.open(path, '_blank');
            return;
        }

        Router.navigate(path).then(x => setRoute(path));
    }

    const adminNavigate = (path: string, external = false) => {
        if (external) {
            window.open(path, '_blank');
            return;
        }

        AdminRouter.navigate(path).then(x => setRoute(path));
    }

    const handleLogout = () => {
        localStorage.removeItem('auth');
        setAuthCase({} as AuthCase);
    };

    return {
        authCase,
        route,
        handleLogin,
        handleAdminLogin,
        handleSystemLogin,
        handleExternalLogin,
        handleLogout,
        navigate,
        adminNavigate
    };
}
