import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import React, {useEffect, useState} from "react";
import {AuthorType, Report, Reports, ReportState} from "../../../types/Reports";
import {CloseOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, SaveOutlined} from "@ant-design/icons";
import {Empty, Form, Input, notification, Skeleton, Space, Table, Tooltip} from "antd";
import {useAuth} from "../../../extensions/Auth";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {FormatDateSimpleWithTime} from "../../../extensions/DateFormatter";
import './ViewReports.css';
import SignalistAdminService from "../../../services/SignalistAdminService";
import {ColumnsType} from "antd/es/table";
import ConfirmationModal, {ConfirmationModalProps} from "../../../designsystems/ConfirmationModal/ConfirmationModal";
import ReportDateRangeModal from "./ReportDateRangeModal/ReportDateRangeModal";
import GenerateNewReportIdModal from "./GenerateNewReportIdModal/GenerateNewReportIdModal";

export const ReportStateMapper = (state: ReportState) => {
    switch (state) {
        case ReportState.New:
            return <span className="text-orange-600">Oczekujące</span>;
        case ReportState.Read:
            return <span className="text-blue-600">Odczytano</span>;
        case ReportState.Answered:
            return <span className="text-blue-600">Nowa odpowiedź</span>;
        case ReportState.Investigation:
            return <span>W trakcie wyjaśniania</span>;
        case ReportState.Closed:
            return <span className="text-gray-600">Zamknięte</span>;
        case ReportState.Resolved:
            return <span className="text-green-600">Rozwiązane</span>;
    }
}

export default function ViewReports() {
    const [api, contextHolder] = notification.useNotification();
    const [categories, setCategories] = useState([] as Reports[]);
    const [types, setTypes] = useState([] as Reports[]);
    const [reports, setReports] = useState([] as Report[]);
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [categoryEdit, setCategoryEdit] = useState('');
    const [typeEdit, setTypeEdit] = useState('');
    const [newCategoryTitle, setNewCategoryTitle] = useState('');
    const [newCategoryShortTitle, setNewCategoryShortTitle] = useState('');
    const [newTypeTitle, setNewTypeTitle] = useState('');
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [confirmModal, setConfirmModal] = useState(false);
    const [dateModalOpen, setDateModalOpen] = useState(false);
    const [idModalOpen, setIdModalOpen] = useState(false);
    const [categoryForm] = Form.useForm();
    const [typeForm] = Form.useForm();
    const [passwordForm] = Form.useForm();

    const editCategory = (id: string) => {
        setCategoryEdit(id);

        const category = categories.find(x => x.id === id);

        setNewCategoryTitle(category?.title || '');
        setNewCategoryShortTitle(category?.shortTitle || '');
    }

    const editType = (id: string) => {
        setTypeEdit(id);
        setNewTypeTitle(types.find(x => x.id === id)?.title || '');
    }

    const saveEdit = () => {
        SignalistAdminService.updateCategory(categoryEdit, newCategoryTitle, newCategoryShortTitle)
            .then(() => {
                setCategoryEdit('');
                setNewCategoryTitle('');
                setNewCategoryShortTitle('');
                reloadCategories();
            })
            .catch((e) => {
                setCategoryEdit('');
                setNewCategoryTitle('');
                setNewCategoryShortTitle('');

                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się zapisać kategorii',
                    placement: 'top'
                });
            });
    }

    const saveTypeEdit = () => {
        SignalistAdminService.updateType(typeEdit, newTypeTitle)
            .then(() => {
                setTypeEdit('');
                setNewTypeTitle('');
                reloadTypes();
            })
            .catch((e) => {
                setTypeEdit('');
                setNewTypeTitle('');

                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się zapisać statusu',
                    placement: 'top'
                });
            });
    }

    const deleteCategory = (id: string) => {
        SignalistAdminService.deleteCategory(id)
            .then(() => {
                reloadCategories();
            })
            .catch((e) => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się usunąć kategorii',
                    placement: 'top'
                });
            });
    }

    const deleteType = (id: string) => {
        SignalistAdminService.deleteType(id)
            .then(() => {
                reloadTypes();
            })
            .catch((e) => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się usunąć statusu',
                    placement: 'top'
                });
            });
    }

    const categoryColumns: ColumnsType<Reports> = [
        {
            title: 'Nazwa kategorii',
            dataIndex: 'title',
            key: 'title',
            width: '70%',
            render: (value, record) => <>
                {categoryEdit === record.id ?
                    <Input defaultValue={value} value={newCategoryTitle} className="rounded-none"
                           onChange={(e) => setNewCategoryTitle(e.target.value)}/>
                    :
                    <span>{value}</span>}
            </>
        },
        {
            title: 'Skrót',
            dataIndex: 'shortTitle',
            key: 'shortTitle',
            width: '20%',
            render: (value, record) => <>
                {categoryEdit === record.id ?
                    <Input defaultValue={value} value={newCategoryShortTitle} className="rounded-none"
                           onChange={(e) => setNewCategoryShortTitle(e.target.value)}/>
                    :
                    <span>{value}</span>}
            </>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className="h-8">
                    {categoryEdit === record.id ? <>
                            <Tooltip title={'Zapisz'}>
                                <SaveOutlined className="cursor-pointer" onClick={() => saveEdit()}/>
                            </Tooltip>
                            <Tooltip title={'Anuluj'}>
                                <CloseOutlined className="cursor-pointer" onClick={() => setCategoryEdit('')}/>
                            </Tooltip>
                        </> :
                        <>
                            <Tooltip title={'Edytuj'}>
                                <EditOutlined className="cursor-pointer" onClick={() => editCategory(record.id)}/>
                            </Tooltip>
                            <Tooltip title={'Usuń'}>
                                <DeleteOutlined onClick={() => {
                                    const payload = {
                                        title: 'Usuń status',
                                        open: true,
                                        setOpen: setConfirmModal,
                                        yesAction: () => {
                                            deleteCategory(record.id)
                                            setConfirmModal(false);
                                        },
                                        noAction: () => setConfirmModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrany kategoria zgłoszenia zostanie usunięta"
                                    payload.children = <>
                                        <p className="text-slate-600">Usunięta kategoria nie może zostać przywrócony,
                                            czy
                                            jesteś pewien, że chcesz usunąć {record.title}?</p>
                                    </>

                                    setModelPayload(payload);
                                    setConfirmModal(true);
                                }}/>
                            </Tooltip>
                        </>}
                </Space>
            ),
        }
    ];

    const typesColumns: ColumnsType<Reports> = [
        {
            title: 'Nazwa statusu',
            dataIndex: 'title',
            key: 'title',
            width: '80%',
            render: (value, record) => <>
                {typeEdit === record.id ?
                    <Input defaultValue={value} value={newTypeTitle} className="rounded-none"
                           onChange={(e) => setNewTypeTitle(e.target.value)}/>
                    :
                    <span>{value}</span>}
            </>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle" className="h-8">
                    {typeEdit === record.id ? <>
                            <Tooltip title={'Zapisz'}>
                                <SaveOutlined className="cursor-pointer" onClick={() => saveTypeEdit()}/>
                            </Tooltip>
                            <Tooltip title={'Anuluj'}>
                                <CloseOutlined className="cursor-pointer" onClick={() => setTypeEdit('')}/>
                            </Tooltip>
                        </> :
                        <>
                            <Tooltip title={'Edytuj'}>
                                <EditOutlined className="cursor-pointer" onClick={() => editType(record.id)}/>
                            </Tooltip>
                            <Tooltip title={'Usuń'}>
                                <DeleteOutlined onClick={() => {
                                    const payload = {
                                        title: 'Usuń status',
                                        open: true,
                                        setOpen: setConfirmModal,
                                        yesAction: () => {
                                            deleteType(record.id)
                                            setConfirmModal(false);
                                        },
                                        noAction: () => setConfirmModal(false),
                                    } as ConfirmationModalProps;

                                    payload.infoboxTitle = "Wybrany status osoby zgłaszającej zostanie usunięty"
                                    payload.children = <>
                                        <p className="text-slate-600">Usunięty status nie może zostać przywrócony, czy
                                            jesteś pewien, że chcesz usunąć {record.title}?</p>
                                    </>

                                    setModelPayload(payload);
                                    setConfirmModal(true);
                                }}/>
                            </Tooltip>
                        </>}
                </Space>
            ),
        }
    ];

    const addCategory = () => {
        categoryForm.validateFields()
            .then(x => {
                SignalistAdminService.addCategory(x.title, x.shortTitle)
                    .then(x => {
                        reloadCategories();
                        categoryForm.resetFields();
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się dodać kategorii',
                            placement: 'top'
                        })
                    });
            });
    }

    const addType = () => {
        typeForm.validateFields()
            .then(x => {
                SignalistAdminService.addType(x.title)
                    .then(x => {
                        reloadTypes();
                        typeForm.resetFields();
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się dodać typu',
                            placement: 'top'
                        })
                    });
            });
    }

    const updatePassword = () => {
        passwordForm.validateFields()
            .then(values => {
                if (values.password !== values.passwordConfirmation) {
                    api.error({
                        message: 'Wystąpił błąd',
                        description: 'Podane hasła nie są takie same!',
                        placement: 'top'
                    });

                    return;
                }

                SignalistAdminService.updatePassword(values.password, values.passwordConfirmation)
                    .then(x => {
                        api.success({
                            message: 'Hasło zostało zmienione',
                            description: 'Przekaż nowe hasło pracownikom, by dalej mogli korzystać z systemu',
                            placement: 'top'
                        });

                        passwordForm.resetFields();
                    })
                    .catch(x => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się zmienić hasła',
                            placement: 'top'
                        });
                    })
            })
    }

    const reloadCategories = () => {
        SignalistAdminService.getReportCategories()
            .then(x => {
                setCategories(x);
            });
    }

    const reloadTypes = () => {
        SignalistAdminService.getTypes()
            .then(x => {
                setTypes(x);
            });
    }

    const getCategory = (id: string) => {
        const cat = categories.find(x => x.id === id);

        return cat ? cat.title : 'Nieznana';
    }

    const getReporterStatus = (id: string) => {
        const cat = types.find(x => x.id === id);

        return cat ? cat.title : 'Nieznany';
    }

    const columns: ColumnsType<Report> = [
        {
            title: 'Data utworzenia',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value, record) => <span>{FormatDateSimpleWithTime(value)}</span>
        },
        {
            title: 'Tytuł',
            dataIndex: 'title',
            key: 'title',
            render: (value, record) => <span>{value}</span>
        },
        {
            title: 'Liczba wiadomości',
            dataIndex: 'messages',
            key: 'messages',
            responsive: ['lg'],
            render: (value, record) => <span>{value.length}</span>
        },
        {
            title: 'Liczba załączników',
            dataIndex: 'attachments',
            key: 'attachments',
            responsive: ['lg'],
            render: (value, record) => <span>{value.length}</span>
        },
        {
            title: 'Status zgłoszenia',
            dataIndex: 'status',
            key: 'status',
            render: (value, record) => <span>{ReportStateMapper(value)}</span>
        },
        {
            title: 'Kategoria',
            dataIndex: 'categoryId',
            key: 'categoryId',
            render: (value, record) => <span>{getCategory(value)}</span>
        },
        {
            title: 'Status zgłaszającego',
            dataIndex: 'reportingStatusId',
            key: 'reportingStatusId',
            render: (value, record) => <span>{getReporterStatus(value)}</span>
        },
        {
            title: 'Tryb zgłoszenia',
            dataIndex: 'reportMode',
            key: 'reportMode',
            render: (value, record) => <span>{value === 0 ? 'Utajnione' : 'Jawne'}</span>
        },
        {
            title: 'Akcje',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title={'Otwórz raport'}>
                        <InfoCircleOutlined className="cursor-pointer" onClick={() => {
                            auth.adminNavigate('/reports/view/' + record.id);
                        }}/>
                    </Tooltip>
                </Space>
            ),
        }
    ];

    useEffect(() => {
        if (!auth.authCase?.isAdmin) {
            auth.handleLogout();
            return;
        }

        SignalistAdminService.getReportCategories()
            .then(x => {
                setCategories(x);
            })
            .catch(err => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się pobrać kategorii zgłoszeń',
                    placement: 'top'
                })
            })
            .then(x => {
                SignalistAdminService.getTypes()
                    .then(x => {
                        setTypes(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać typów osób zgłaszających',
                            placement: 'top'
                        })
                    })
            })
            .then(x => {
                SignalistAdminService.getReports()
                    .then(x => {
                        setReports(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać zgłoszeń',
                            placement: 'top'
                        })
                    })
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
        };
    }, [auth]);

    return (
        <div className="content">
            {contextHolder}
            <RetoricBreadcrumbs locations={Locations['admin/reports']} isAdmin>Zgłoszenia</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite'}>
                <div className="flex lg:flex-row flex-col justify-between">
                    <Space className="flex-1 mb-4 lg:justify-start justify-center">
                        <RetoricButton type={'primary'} outline onClick={() => auth.adminNavigate('/reports/new')}>Dodaj
                            zgłoszenie</RetoricButton>
                        <RetoricButton type={'primary'} outline onClick={() => setIdModalOpen(true)}>
                            Wygeneruj identyfikator
                        </RetoricButton>
                        <RetoricButton type={'primary'} outline onClick={() => setDateModalOpen(true)}>
                            Wygeneruj raport
                        </RetoricButton>
                    </Space>
                    <div className={'infopages__new'}></div>
                </div>
                <Table columns={columns} dataSource={reports} rowKey={'id'}
                       scroll={{x: 768}}
                       locale={{emptyText: <Empty description={'Brak danych'}/>}}/>
            </RetoricCard>
            <div className="content">
                {!loading && <div className={'self-stretch grid lg:grid-cols-3 grid-cols-1 gap-4'}>
                    <RetoricCard className="bg-offwhite self-stretch gap-4 flex flex-col">
                        <h2 className="text-lg tracking-wider font-ligh">Zmień hasło sygnalisty</h2>
                        <p>To ustawienie pozwala na zmianę hasła do Signalist. Po zmianie hasła przekaż je
                            wszystkim
                            pracownikom, aby dalej mogli korzystać z systemu.</p>
                        <Form className="flex flex-col justify-between flex-1"
                              onFinish={updatePassword}
                              form={passwordForm}
                              layout={'vertical'}>
                            <div className="flex flex-col">
                                <Form.Item label={"Nowe hasło"} required name="password"
                                           className="flex-1" id={'password'}
                                           rules={[{required: true, message: 'Hasło jest wymagane!'}]}>
                                    <Input.Password placeholder={'Nowe hasło...'} className="rounded-none"
                                                    size="large"/>
                                </Form.Item>
                                <Form.Item label={"Powtórz nowe hasło"} required name="passwordConfirmation"
                                           className="flex-1" id={'passwordConfirmation'}
                                           rules={[{required: true, message: 'Hasło jest wymagane!'}]}>
                                    <Input.Password placeholder={'Nowe hasło...'} className="rounded-none"
                                                    size="large"/>
                                </Form.Item>
                            </div>
                            <Form.Item>
                                <RetoricButton htmlType={'submit'} size="md" type="primary">Zmień hasło</RetoricButton>
                            </Form.Item>
                        </Form>
                    </RetoricCard>
                    <RetoricCard className="bg-offwhite flex flex-col gap-4 justify-between">
                        <div className="flex flex-col gap-2 self-stretch flex-1">
                            <h2 className="text-lg tracking-wider font-ligh">Kategorie zgłoszeń</h2>
                            <Table columns={categoryColumns} dataSource={categories} rowKey={'id'}
                                   pagination={{position: ['bottomCenter']}}
                                   locale={{emptyText: <span>Brak kategorii...</span>}}/>
                        </div>
                        <Form
                            onFinish={addCategory}
                            form={categoryForm}
                            className="flex flex-col items-stretch"
                            layout={'vertical'}>
                            <Form.Item label={"Nazwa kategorii"} required name="title"
                                       className="flex-1"
                                       rules={[{required: true, message: 'Nazwa kategorii jest wymagana!'}]}>
                                <Input placeholder={'Nazwa kategorii...'} className="rounded-none" size="large"
                                       id={'catTitle'}/>
                            </Form.Item>
                            <div className="flex flex-row gap-4 items-end">
                                <Form.Item label={"Skrót nazwy działu"} required name="shortTitle"
                                           className="flex-1"
                                           rules={[{required: true, message: 'Skrót nazwy działu jest wymagany!'}]}>
                                    <Input placeholder={'Skrót...'} className="rounded-none" size="large"
                                           id={'typeTitle'}/>
                                </Form.Item>
                            <Form.Item>
                                <RetoricButton type={'primary'} htmlType={'submit'}>Dodaj kategorię</RetoricButton>
                            </Form.Item>
                            </div>
                        </Form>
                    </RetoricCard>
                    <RetoricCard className="bg-offwhite flex flex-col gap-4 justify-between">
                        <div className="flex flex-col gap-2 self-stretch flex-1">
                            <h2 className="text-lg tracking-wider font-ligh">Statusy osób zgłaszających</h2>
                            <Table columns={typesColumns} dataSource={types} rowKey={'id'}
                                   pagination={{position: ['bottomCenter']}}
                                   locale={{emptyText: <span>Brak statusów...</span>}}/>
                        </div>
                        <Form
                            onFinish={addType}
                            form={typeForm}
                            className="flex flex-row items-end gap-4"
                            layout={'vertical'}>
                            <Form.Item label={"Nazwa statusu"} required name="title"
                                       className="flex-1"
                                       rules={[{required: true, message: 'Nazwa statusu jest wymagana!'}]}>
                                <Input placeholder={'Nazwa statusu...'} className="rounded-none" size="large"
                                       id={'typeTitle'}/>
                            </Form.Item>
                            <Form.Item>
                                <RetoricButton type={'primary'} htmlType={'submit'}>Dodaj status</RetoricButton>
                            </Form.Item>
                        </Form>
                    </RetoricCard>
                </div>}
                {loading && <Skeleton active/>}
                <ConfirmationModal {...modelPayload} open={confirmModal} setOpen={setConfirmModal}/>
                <ReportDateRangeModal open={dateModalOpen} setOpen={setDateModalOpen}/>
                <GenerateNewReportIdModal open={idModalOpen} setOpen={setIdModalOpen}/>
            </div>
        </div>
    );
}
