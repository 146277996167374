import SignalistAdminService from "../../../services/SignalistAdminService";
import {useAuth} from "../../../extensions/Auth";
import {Checkbox, Form, Input, notification, Pagination, Select, Skeleton} from "antd";
import React, {useEffect, useState} from "react";
import {AuthorType, Report, ReportMessage, ReportMessageRequest, Reports, ReportState} from "../../../types/Reports";
import ConfirmationModal, {ConfirmationModalProps} from "../../../designsystems/ConfirmationModal/ConfirmationModal";
import {Attachment} from "../../../types/StaticAsset";
import {useParams} from "react-router-dom";
import Locations from "../../../extensions/Locations";
import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import {FormatDateSimpleWithTime} from "../../../extensions/DateFormatter";
import {AuthorMapper, ReportStateMapper} from "../../ReportStatus/ReportStatus";
import TextArea from "antd/es/input/TextArea";
import AttachmentsDragger from "../../../components/AttachmentsDragger/AttachmentsDragger";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";

const PAGE_SIZE = 5;

export default function ViewReport() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);
    const [activeReport, setActiveReport] = useState({} as Report);
    const [categories, setCategories] = useState([] as Reports[]);
    const [types, setTypes] = useState([] as Reports[]);
    const [modelPayload, setModelPayload] = useState({} as ConfirmationModalProps);
    const [confirmModal, setConfirmModal] = useState(false);
    const [messages, setMessages] = useState([] as ReportMessage[]);
    const [activePage, setActivePage] = useState(1);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);
    const [reportStatus, setReportStatus] = useState(ReportState.New);

    const [msgForm] = Form.useForm();

    const {id} = useParams();

    const filter = (p = 1, input = activeReport.messages) => setMessages(input.filter((_, index) => index >= (p - 1) * PAGE_SIZE && index < p * PAGE_SIZE));

    const refreshReport = (reportId: string) => {
        SignalistAdminService.getReport(reportId)
            .then(x => {
                setActiveReport(x);
                setReportStatus(x.status);

                filter(activePage, x.messages);
            })
            .catch(x => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się pobrać raportu',
                    placement: 'top'
                })

                auth.adminNavigate('/signalist');
            })
    }

    const submitMessage = () => {
        msgForm.validateFields().then((values) => {
            const request: ReportMessageRequest = {
                ...values,
                attachments: attachments.map(x => x.asset),
            }

            SignalistAdminService.addReportMessage(activeReport.id, request)
                .then(x => {
                    api.success({
                        message: 'Wysłano wiadomość',
                        description: 'Wiadomość została wysłana pomyślnie',
                        placement: 'top'
                    });

                    msgForm.resetFields();

                    setAttachments([]);
                    setFileList([]);

                    const page = Math.ceil(x.messages.length / PAGE_SIZE);
                    filter(page, x.messages);
                    setActivePage(page);
                    setActiveReport(x);
                })
                .catch(x => {
                    api.error({
                        message: 'Wystąpił błąd',
                        description: 'Nie udało się wysłać wiadomości',
                        placement: 'top'
                    })
                });
        });
    }

    const updateReportStatus = (newStatus: ReportState) => {
        SignalistAdminService.updateStatus(activeReport.id, newStatus)
            .then(x => {
                api.success({
                    message: 'Zaktualizowano status',
                    description: 'Status został zaktualizowany pomyślnie',
                    placement: 'top'
                });

                setReportStatus(newStatus);
            })
            .catch(x => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się zaktualizować statusu',
                    placement: 'top'
                })
            });
    }

    const locations = [...Locations['admin/reports'], {
        title: `Przegląd raportu: ${activeReport.title}`,
        path: `/admin/reports/view/${id}`
    }]

    useEffect(() => {
        if (!auth.authCase?.isAdmin) {
            auth.handleLogout();
            return;
        }

        if (!id) {
            auth.adminNavigate('/reports')
            return;
        }

        SignalistAdminService.getReportCategories()
            .then(x => {
                setCategories(x);
            })
            .catch(err => {
                api.error({
                    message: 'Wystąpił błąd',
                    description: 'Nie udało się pobrać kategorii zgłoszeń',
                    placement: 'top'
                })
            })
            .then(x => {
                SignalistAdminService.getTypes()
                    .then(x => {
                        setTypes(x);
                    })
                    .catch(err => {
                        api.error({
                            message: 'Wystąpił błąd',
                            description: 'Nie udało się pobrać typów osób zgłaszających',
                            placement: 'top'
                        })
                    })
            })
            .then(x => {
                refreshReport(id!);
            })
            .finally(() => {
                setLoading(false);
            });

    }, []);

    return (
        <div className="content">
            {contextHolder}
            <RetoricBreadcrumbs locations={locations} isAdmin>Zgłoszenie: {activeReport.title}</RetoricBreadcrumbs>
            {!loading && activeReport && (
                <div className="flex flex-col justify-center items-center self-stretch">
                    <RetoricCard className="self-stretch flex flex-col gap-4 bg-none report lg:p-4 p-0">
                        <div className="flex flex-col">
                            <h2 className="text-md tracking-wide italic font-light text-center lg:text-left mb-4">Identyfikator
                                zgłoszenia: {activeReport.id}</h2>
                            <div className="flex lg:flex-row flex-col lg:justify-between items-center">
                                <span
                                    className="italic">Zgłoszenie utworzono: {FormatDateSimpleWithTime(activeReport.createdAt)}</span>
                                <span
                                    className="italic">Zgłaszający: {activeReport.name ?? 'Anonimowy'} ({activeReport.reportMode === 0 ? "Utajnione" : "Jawne"})</span>
                                <span
                                    className="italic">Kategoria: {categories.find(x => x.id === activeReport.categoryId)?.title}</span>
                                <span
                                    className="italic">Status osoby zgłaszającej: {types.find(x => x.id === activeReport.reportingStatusId)?.title}</span>
                                <span className="italic">Status zgłoszenia: <Select className="min-w-[200px]"
                                                                                    onChange={(value) => updateReportStatus(value as ReportState)}
                                                                                    defaultValue={activeReport.status}
                                                                                    value={reportStatus}>
                                    <Select.Option
                                        value={ReportState.New}>{ReportStateMapper(ReportState.New)}</Select.Option>
                                    <Select.Option
                                        value={ReportState.Read}>{ReportStateMapper(ReportState.Read)}</Select.Option>
                                    <Select.Option
                                        value={ReportState.Answered}>{ReportStateMapper(ReportState.Answered)}</Select.Option>
                                    <Select.Option
                                        value={ReportState.Investigation}>{ReportStateMapper(ReportState.Investigation)}</Select.Option>
                                    <Select.Option
                                        value={ReportState.Closed}>{ReportStateMapper(ReportState.Closed)}</Select.Option>
                                    <Select.Option
                                        value={ReportState.Resolved}>{ReportStateMapper(ReportState.Resolved)}</Select.Option>
                                </Select></span>
                            </div>
                        </div>
                        <RetoricCard className="bg-offwhite flex flex-col">
                            <h3 className="text-lg tracking-wider">Treść zgłoszenia</h3>
                            <div className={"flex flex-col"}>
                                {activeReport?.description && activeReport.description.split('\n').map((x, index) => <p
                                    key={`message-${index}`}>{x}</p>)}
                            </div>
                            <div>
                                <h3 className="text-lg tracking-wider font-light">Załączniki</h3>
                                <ul>
                                    {activeReport?.attachments && activeReport.attachments.map(x => <li
                                        key={`attachment-${x.id}`}><span className="cursor-pointer hover:text-red-600"
                                                                         onClick={() => SignalistAdminService.openAttachment(x.name)}>{x.name}</span>
                                    </li>)}
                                    {activeReport?.attachments && activeReport.attachments.length === 0 &&
                                        <span className="italic">Brak załączników</span>}
                                </ul>
                            </div>
                        </RetoricCard>
                        <RetoricCard className="bg-none flex flex-col self-stretch gap-4 p-0 lg:mt-4">
                            <h3 className="text-lg tracking-wider">Wiadomości</h3>
                            {activeReport?.messages && <div className="flex flex-col gap-4">
                                {messages.map(x =>
                                    <RetoricCard key={`message-${x.createdAt}`}
                                                 className={`flex flex-col gap-2 ${x.authorType === AuthorType.User ? 'lg:ml-16 ml-8 bg-offwhite' : 'lg:mr-16 mr-8 bg-gray-200/30 text-slate-950'}`}>
                                        <div>
                                            <span>Wiadomość wysłana {FormatDateSimpleWithTime(activeReport.createdAt)} przez {AuthorMapper(x.authorType)} {x.authorType === AuthorType.Operator && x.author && `(${x.author.name})`}</span>
                                            <h3 className="text-lg tracking-wider font-light mb-1">{x.title}</h3>
                                            <div className={"flex flex-col"}>
                                                {x?.description && x.description.split('\n').map((x, index) => <p
                                                    key={`message-${index}`}>{x}</p>)}
                                            </div>
                                            {x?.attachments && x.attachments.length > 0 && <div>
                                                <h3 className="text-md tracking-wider font-light italic mt-2">Załączniki</h3>
                                                <ul>
                                                    {x?.attachments && x.attachments.map(x => <li
                                                        key={`attachment-${x.id}`}><span
                                                        className="cursor-pointer hover:text-red-600"
                                                        onClick={() => SignalistAdminService.openAttachment(x.name)}>{x.name}</span>
                                                    </li>)}
                                                </ul>
                                            </div>}
                                        </div>
                                    </RetoricCard>)
                                }
                                {messages.length > 0 && <Pagination className="self-center" current={activePage}
                                                                    locale={{
                                                                        next_page: "Następna strona",
                                                                        prev_page: "Poprzednia strona"
                                                                    }}
                                                                    pageSize={PAGE_SIZE}
                                                                    total={activeReport?.messages.length}
                                                                    onChange={(p) => {
                                                                        filter(p)
                                                                        setActivePage(p)
                                                                    }}/>}
                                {messages.length === 0 && <span className="italic">Brak wiadomości</span>}
                            </div>}
                        </RetoricCard>
                        <RetoricCard className="bg-none flex flex-col self-stretch gap-4 p-0 lg:mt-4">
                            <h3 className="text-lg tracking-wider">Wyślij wiadomość</h3>
                            <Form
                                onFinish={submitMessage}
                                form={msgForm}
                                layout="vertical">
                                <Form.Item label="Tytuł wiadomości" required name="title"
                                           rules={[{required: true, message: "Tytuł wiadomości jest wymagany!"}]}>
                                    <Input className="rounded-none" placeholder="Tytuł wiadomości..." size="large"/>
                                </Form.Item>
                                <Form.Item label="Treść wiadomości" required name="description"
                                           rules={[{required: true, message: "Treść wiadomości jest wymagana!"}]}>

                                    <TextArea rows={6} placeholder={"Treść wiadomości..."}></TextArea>
                                </Form.Item>
                                <Form.Item>
                                    <AttachmentsDragger attachments={attachments}
                                                        setAttachments={setAttachments}
                                                        fileList={fileList} setFileList={setFileList}/>
                                </Form.Item>
                                <Form.Item
                                    name="isResolution"
                                    valuePropName="checked"
                                    label={'Wyjaśnienia i działania następcze'}
                                    tooltip={'Zaznacz, jeśli ta wiadomość zawiera wyjaśnienia i działania następcze tego zgłoszenia'}
                                >
                                    <Checkbox>Ta wiadomość zawiera wyjaśnienia i działania następcze tego zgłoszenia</Checkbox>
                                </Form.Item>
                                <Form.Item className="flex justify-center">
                                    <RetoricButton htmlType="submit"
                                                   className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Wyślij
                                        wiadomość</RetoricButton>
                                </Form.Item>
                            </Form>
                        </RetoricCard>
                    </RetoricCard>
                </div>
            )}
            {loading && <Skeleton active/>}
            <ConfirmationModal {...modelPayload} open={confirmModal} setOpen={setConfirmModal}/>
        </div>
    )
}
