import RetoricBreadcrumbs from "../../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../../extensions/Locations";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../../extensions/Auth";
import './UserEdit.css';
import {Form, Input, notification} from "antd";
import {User} from "../../../../types/User";
import RetoricCard from "../../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import {UserService} from "../../../../services/UserService";
import {useParams} from "react-router-dom";

export default function UserEdit() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [user, setUser] = useState({} as User);
    const [form] = Form.useForm();
    const {id} = useParams();

    const updateUser = () => {
        form.validateFields().then((values) => {
            UserService.editUser(values, id!).then((x) => {
                if ("error" in x) {
                    api.error({
                        message: `Błąd`,
                        description: x.error,
                        placement: 'top'
                    });
                    return;
                }

                api.info({
                    message: `Operator zapisany`,
                    description: `Operator ${values.name} został zaktualizowany`,
                    placement: 'top'
                });
            });
        });
    }

    useEffect(() => {
        if (!auth.authCase?.isAdmin) {
            auth.handleLogout();
            return;
        }

        if (!id) {
            auth.adminNavigate('/signalist')
            return;
        }

        UserService.getUser(id)
            .then((x) => {
                if ("error" in x) {
                    api.error({
                        message: `Błąd`,
                        description: x.error,
                        placement: 'top'
                    });
                    return;
                }

                setUser(x);
                form.setFieldsValue(x);
            });

        return () => {
        };
    }, [auth]);
    return (
        <div>
            <RetoricBreadcrumbs locations={Locations['admin/users/new']} isAdmin>Edytuj operatora</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite mt-4'}>
                {contextHolder}
                <Form
                    form={form}
                    onFinish={updateUser}
                    className="flex lg:flex-row flex-col lg:items-end items-stretch lg:gap-4"
                    layout={'vertical'}>
                    <Form.Item label={"Imię i nazwisko"} required name="name"
                               className="flex-1"
                               rules={[{required: true, message: 'Imię i nazwisko jest wymagane!'}]}>
                        <Input placeholder={'Jan Nowak...'} className="rounded-none" size="large" id={'catTitle'}/>
                    </Form.Item>
                    <Form.Item label={"Email"} required name="email"
                               className="flex-1"
                               rules={[{required: true, message: 'Email jest wymagany!'}]}>
                        <Input placeholder={'jan@domena.pl...'} className="rounded-none" size="large" id={'catTitle'}/>
                    </Form.Item>
                    <Form.Item>
                        <RetoricButton type={'primary'} htmlType={'submit'}>Zapisz operatora</RetoricButton>
                    </Form.Item>
                </Form>
            </RetoricCard>
        </div>
    )
}
