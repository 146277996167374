import {LeftOutlined} from "@ant-design/icons";
import {Form, Input, message} from "antd";
import RetoricButton from "../../designsystems/RetoricButton/RetoricButton";
import RetoricCard from "../../designsystems/RetoricCard/RetoricCard";
import React from "react";
import {LoginFormProps} from "../NewLoginForm/NewLoginForm";
import {useAuth} from "../../extensions/Auth";

export default function OperatorLoginForm({goBack, form}: LoginFormProps) {
    const auth = useAuth();

    const authenticate = () => {
        form.validateFields().then((values) => {
            auth.handleAdminLogin(values.email, values.password)
                .then((x: boolean) => {
                    if (x) {
                        message.success('Zalogowano pomyślnie!');
                        auth.navigate('/');
                    } else {
                        message.error('Nieprawidłowe hasło!');
                    }
                });
        });
    }

    return (
        <RetoricCard
            className="bg-offwhite flex flex-col 2xl:px-16 xl:px-12 lg:px-8 lg:py-8 gap-4 justify-between items-center min-w-full">
            <div className="self-stretch flex flex-col gap-2 items-stretch">
                        <span className="block self-start text-slate-500 cursor-pointer mb-4"
                              onClick={goBack}><LeftOutlined className="text-sm"/> Powrót</span>
                <h1 className="text-2xl tracking-wider font-light">Logowanie operatora</h1>
                <p className="text-justify mb-8">Podaj swój adres email oraz hasło, aby uzyskać dostęp do panelu
                    operatora.</p>
                <Form layout="vertical" className="self-stretch" form={form}
                      onFinish={authenticate}>
                    <Form.Item label="Adres e-mail" required name="email"
                               rules={[{required: true, message: "Adres e-mail jest wymagany!"}]}>
                        <Input className="rounded-none" placeholder="jan@domena.pl" size="large"/>
                    </Form.Item>
                    <Form.Item label="Hasło" required name="password"
                               rules={[{required: true, message: "Hasło jest wymagane!"}]}>
                        <Input.Password className="rounded-none" placeholder="Hasło..." size="large"
                                        type="password"/>
                    </Form.Item>
                    <Form.Item>
                        <RetoricButton htmlType="submit" size="lg"
                                       className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Zaloguj</RetoricButton>
                    </Form.Item>
                </Form>
            </div>
        </RetoricCard>
    )
}
