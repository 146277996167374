import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import {RedoOutlined} from "@ant-design/icons";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import React, {useEffect, useState} from "react";
import Infobox from "../../../designsystems/Infobox/Infobox";
import './NewOperatorReport.css'
import {DatePicker, Form, Input, message, Radio, Select, Tooltip} from "antd";
import {ReportingStatus, ReportOperatorRequest, Reports} from "../../../types/Reports";
import ReportService from "../../../services/ReportService";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {Attachment} from "../../../types/StaticAsset";
import AttachmentsDragger from "../../../components/AttachmentsDragger/AttachmentsDragger";
import TextArea from "antd/es/input/TextArea";
import SignalistAdminService from "../../../services/SignalistAdminService";
import pl from "antd/es/date-picker/locale/pl_PL";
import ReportConfirmationModal from "./ReportConfirmationModal/ReportConfirmationModal";

export default function NewOperatorReport() {
    const [form] = Form.useForm();
    const [reportMode, setReportMode] = useState(0);
    const [categories, setCategories] = useState([] as Reports[]);
    const [reportingStatuses, setReportingStatuses] = useState([] as ReportingStatus[]);
    const [attachments, setAttachments] = useState([] as Attachment[]);
    const [fileList, setFileList] = useState([] as any[]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalPayload, setConfirmModalPayload] = useState({} as ReportOperatorRequest);

    const updateReportMode = (mode: number) => {
        setReportMode(mode);
        form.setFieldValue('reportingMode', mode);
        form.setFieldValue('name', '');
    }

    const addReport = (values: any) => {
        const request: ReportOperatorRequest = {
            ...values,
            createdAt: values.createAt?.toDate()?.toISOString(),
            attachments: attachments.map(x => x.asset),
            reportMode: reportMode
        }

        SignalistAdminService.addReport(request)
            .then(x => {
                message.success('Zgłoszenie zostało utworzone!');
                setConfirmModalPayload(request);
                setConfirmModalOpen(true);
                form.resetFields();
            })
            .catch(x => {
                message.error('Wystąpił błąd podczas tworzenia zgłoszenia!');
            });
    }

    const submitReport = () => {
        form.validateFields().then((values) => {
            addReport(values)
        });
    }

    const newId = () => {
        SignalistAdminService.getReportId()
            .then((x) => {
                form.setFieldValue('id', x.replaceAll('"', ''));
            })
            .catch(x => message.error('Wystąpił błąd podczas generowania identyfikatora zgłoszenia.'));
    }

    const newPassword = () => {
        SignalistAdminService.getPassword().then((x) => {
            form.setFieldValue('password', x);
            form.setFieldValue('passwordConfirmation', x);
        });
    }

    useEffect(() => {
        ReportService.getCategories()
            .then((x) => setCategories(x))
            .catch(x => message.error('Wystąpił błąd podczas pobierania kategorii zgłoszeń.'));

        ReportService.getReportingStatuses()
            .then((x) => setReportingStatuses(x))
            .catch(x => message.error('Wystąpił błąd podczas pobierania statusów osoby zgłaszającej.'));

        form.setFieldValue('reportingMode', reportMode);
    }, []);

    return (
        <div>
            <RetoricBreadcrumbs locations={Locations['report/new']} isAdmin>Nowe zgłoszenie</RetoricBreadcrumbs>
            <RetoricCard className="bg-offwhite mt-4 lg:px-8 flex flex-col gap-4">
                <h2 className="text-xl tracking-wider font-light">Podaj szczegóły zgłoszenia</h2>
                <Infobox title={'Zgłoszenie operatora'}>
                    <div className="text-slate-600 infobox flex flex-col gap-1 text-justify">
                        <p className="mb-1 font-medium">Jeżeli zgłoszenie dotarło do Ciebie innym środkiem komunikacji,
                            poza systemem Signalist, możesz wprowadziċ je do systemu za pomocą tego formularza.</p>
                    </div>
                </Infobox>
                <Form
                    onFinish={submitReport}
                    form={form}
                    layout="vertical">
                    <Form.Item label="Identyfikator zgłoszenia" required name="id"
                               rules={[{required: true, message: "Identyfikator zgłoszenia jest wymagany!"}, {
                                   pattern: new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$'),
                                   message: 'Identyfikator ma nieodpowiedni format, skorzystaj z generatora by wygenerować nowy identyfikator!'
                               }]}>
                        <Input className="rounded-none" placeholder="7eef403d-b1e9-4358-..." size="large"
                               suffix={<Tooltip title="Wygeneruj nowy identyfikator">
                                   <RedoOutlined className="hover:text-brandColor-500" onClick={newId}/>
                               </Tooltip>}
                        />
                    </Form.Item>
                    <Form.Item label="Data wpłynięcia" name="createAt">
                        <DatePicker locale={pl} className="rounded-none w-full" showTime popupClassName={'date-picker'}
                                    placeholder="Data wpłynięcia zgłoszenia..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Tytuł zgłoszenia" required name="title"
                               rules={[{required: true, message: "Tytuł zgłoszenia jest wymagany!"}]}>
                        <Input className="rounded-none" placeholder="Tytuł zgłoszenia..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Tryb zgłoszenia" required name="reportingMode"
                               tooltip={"Tryb zgłoszenia określa, czy zgłoszenie jest imienne (utajnione), czy imienne (jawne). W przypadku zgłoszenia imiennego (utajnionego), dane zgłaszającego będą widoczne TYLKO dla operatora. W pozostałych przypadkach, mogą zostać one udostępnione innym osobom."}
                               rules={[{required: true, message: "Tryb zgłoszenia jest wymagany!"}]}>
                        <Radio.Group size="large" value={reportMode}
                                     onChange={(e) => updateReportMode(e.target.value)}>
                            <Radio value={0}>Imienne (utajnione)</Radio>
                            <Radio value={1}>Imienne (jawne)</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label={"Imię i nazwisko"} required name="name"
                               rules={[{required: true, message: "Imię i nazwisko są wymagane!"}]}>
                        <Input className="rounded-none" placeholder="Imię i nazwisko..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Kategoria" required name="categoryId"
                               rules={[{required: true, message: "Kategoria jest wymagana!"}]}>
                        <Select placeholder={"Wybierz kategorię"} size="large">
                            {categories.map((x) => <Select.Option value={x.id}
                                                                  key={x.id}>{x.title}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Status osoby zgłaszającej" required name="reportingStatusId"
                               rules={[{required: true, message: "Status osoby zgłaszającej jest wymagany!"}]}>
                        <Select placeholder={"Wybierz status"} size="large">
                            {reportingStatuses.map((x) => <Select.Option value={x.id}
                                                                         key={x.id}>{x.title}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label={<span>Hasło
                        <Tooltip title="Wygeneruj losowe hasło">
                                <RedoOutlined className="hover:text-brandColor-600 ml-2" onClick={newPassword}/>
                        </Tooltip></span>} required name="password"
                               rules={[{required: true, message: "Hasło jest wymagane!"}]}>
                        <Input.Password className="rounded-none" placeholder="Hasło..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Powtórz hasło" required name="passwordConfirmation"
                               rules={[{required: true, message: "Powtórz hasło"}]}>
                        <Input.Password className="rounded-none" placeholder="Powtórz hasło..." size="large"/>
                    </Form.Item>
                    <Form.Item label="Treść zgłoszenia" required name="description"
                               rules={[{required: true, message: "Treść zgłoszenia jest wymagana!"}]}>
                        <TextArea rows={6} placeholder={"Treść zgłoszenia..."}></TextArea>
                    </Form.Item>
                    <Form.Item>
                        <AttachmentsDragger attachments={attachments} setAttachments={setAttachments}
                                            fileList={fileList} setFileList={setFileList}/>
                    </Form.Item>
                    <Form.Item className="flex justify-center">
                        <RetoricButton htmlType="submit"
                                       className="lg:min-w-[200px] lg:self-center self-stretch min-w-full mt-4">Utwórz
                            zgłoszenie</RetoricButton>
                    </Form.Item>
                </Form>
            </RetoricCard>
            <ReportConfirmationModal open={confirmModalOpen} setOpen={setConfirmModalOpen}
                                     payload={confirmModalPayload}/>
        </div>
    )
}
