import {createBrowserRouter} from "react-router-dom";
import React from "react";
import ViewReports from "../screens/Admin/ViewReports/ViewReports";
import Users from "../screens/Admin/Users/Users";
import UserAdd from "../screens/Admin/Users/UsedAdd/UserAdd";
import UserEdit from "../screens/Admin/Users/UserEdit/UserEdit";
import ViewReport from "../screens/Admin/ViewReport/ViewReport";
import Profile from "../screens/Admin/Profile/Profile";
import NewReport from "../screens/NewReport/NewReport";
import NewOperatorReport from "../screens/Admin/NewOperatorReport/NewOperatorReport";

const AdminRouter = createBrowserRouter([
    {
        path: "/",
        element: <ViewReports/>,
    },
    {
        path: "/reports",
        element: <ViewReports/>,
    },
    {
        path: "/reports/new",
        element: <NewOperatorReport/>,
    },
    {
        path: "/reports/view/:id",
        element: <ViewReport/>,
    },
    {
        path: "/users",
        element: <Users/>,
    },
    {
        path: "/users/new",
        element: <UserAdd/>,
    },
    {
        path: "/users/edit/:id",
        element: <UserEdit/>,
    },
    {
        path: "/profile",
        element: <Profile/>
    },
    {
        path: '*',
        element: <ViewReports/>,
    }
]);

export default AdminRouter;
