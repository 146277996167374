import {Card, Skeleton} from "antd";
import Meta from "antd/es/card/Meta";
import {InfoCircleTwoTone} from "@ant-design/icons";
import React from "react";

export interface InfoboxProps {
    title: string;
    rows?: string[];
    className?: string;
    children?: React.ReactNode;
}

export default function Infobox({title, rows, className, children}: InfoboxProps) {
    return (
        <Card bordered className={className ?? ''}>
            <Skeleton avatar active loading={false}>
                <Meta
                    avatar={<InfoCircleTwoTone className="text-2xl"/>}
                    title={title}
                    description={children ? children : rows ? rows.map((x, index) => <p key={`infobox-${index}`}
                                                                                        className="text-slate-600">{x}</p>) : ''}
                />
            </Skeleton>
        </Card>
    )
}
